import React from 'react';
import Layout from '../components/layout';
import { Link, graphql } from 'gatsby';
import './blog.sass';
import SEO from '../components/seo';

const Post = ({ node }) => (
  <div className="column is-one-third post">
    <Link to={`${node.fields.slug}`} className="post-inner">
      <div
        className="post-img"
        style={{ backgroundImage: `url(${node.frontmatter.image})` }}
      >
        <img src="/images/1px.png" alt="Square" />
      </div>
      <div className="info">
        <h2>{node.frontmatter.title}</h2>
        <div>
          <span className="author">{node.frontmatter.author}</span> on{' '}
          <span className="date">{node.frontmatter.date}</span>
        </div>
        {node.frontmatter.tags && (
          <div className="tags">
            {node.frontmatter.tags.split(';').map((el, idx) => (
              <span key={idx}>{el}</span>
            ))}
          </div>
        )}
      </div>
    </Link>
  </div>
);

const BlogPage = ({ data, ...rest }) => {
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout pageType={'blog'} pathname={rest.location.pathname}>
      <SEO title="Node Vision | Blog" />
      <div className="page-blog">
        <section className="heading">
          <div className="container">
            <h1>Blog</h1>
          </div>
        </section>
        <section className="posts">
          <div className="container">
            <div className="columns is-multiline">
              {posts.map((el, idx) => (
                <Post node={el.node} key={idx} />
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  query Blogs {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            image
            tags
          }
        }
      }
    }
  }
`;
